.homepage {
  background-image: url('../asset/taipei101.jpeg'); /*攝影師：Timo Volz，連結：Pexels*/
  background-position-x: -15px;
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;    
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(2, 50%);
}

.homepage>div:nth-child(2) {
  place-self: start end;
  padding: 15px 20px;
  font-family: "Playfair Display", serif;
}

.homepage>div>h1 {
  grid-area: 1/2/2/3;
  text-align: center;  
  margin: 0 0 2rem;  
}

.homepage>div>*.chinese {
  font-family: 'Noto Sans TC', sans-serif;
  font-weight: normal;
}

.homepage>div>h3 {  
  font-style: italic;  
  text-align: center;  
  margin: 0;
}

.language {
  grid-area: 1/1/2/2;
  place-self: start start;
  width: 5rem;
  display: flex;
  font-size: 1.2rem;
  align-items: center;
}

.language>button {
  border: 0;
  background-color: transparent;
  color: #fff;
  font-size: 1.2rem;
  font-family: Roboto;
}

.language>button:hover {
  transform-origin: center;
  transform: scale(1.1);

}

.language-select {
  text-decoration: underline;
  font-weight: bold;
}

.homepage>p {
  place-self: end center;
  grid-area: 2/1/3/3;
  padding:1rem 5vw;
  background-color: rgba(0,0,0,0.6);
  font-size: 2rem;
}

@media (min-width: 768px) {
  .homepage {
    background-image: url('../asset/taipei101-desktop.jpeg'); /*攝影師：Timo Volz，連結：Pexels*/
    background-position: 100% 0;
    margin: 0 5vw;  
    font-size: 3rem;
  }

  .homepage>div:first-child {
    padding: 2rem 3rem;
  } 
}