.about {
  overflow: hidden;
  min-height: 100vh;
}

.about>h2 {
  transform-origin: center;
}

.about>p {
  text-align: left;
  margin: 0 30px 20px;
}

.skill-list {
  display: grid;
  grid-template-columns: repeat(3,80px);
  grid-template-rows: repeat(auto-fit,130px);
  justify-content: space-around;
  margin: 10px 30px;
  gap:10px 20px;
}

.run-in-enter {
  opacity: 0;
  transform-origin: center;
  transform: translateX(-100%);
}

.run-in-enter-active {
  opacity: 1;
  transform-origin: center;
  transform: translateX(0);
  transition: all 1s ease-in-out;
}

.run-in-enter-done {
  opacity: 1;
  transform-origin: center;
  transform: translateX(0);
}

.run-in-exit {
  opacity: 1;
  transform-origin: center;
  transform: translateX(0);
}

.run-in-exit-active {
  opacity: 0;
  transform-origin: center;
  transform: translateX(-100%);
  transition: all 1s ease-in-out;
}

.run-in-exit-done {
  opacity: 0;
  transform-origin: center;
  transform: translateX(-100%);
}

.contents-enter {
  opacity: 0;
  transform-origin: center;
  transform: translateX(100%);
}

.contents-enter-active {
  opacity: 1;
  transform-origin: center;
  transform: translateX(0);
  transition: all 1s ease-in-out;
}

.contents-enter-done {
  opacity: 1;
  transform-origin: center;
  transform: translateX(0);
}

.contents-exit {
  opacity: 1;
  transform-origin: center;
  transform: translateX(0) scaleX(100%);
}

.contents-exit-active {
  opacity: 0;
  transform-origin: center;
  transform: translateX(100%) scaleX(0);
  transition: all 1s ease-in-out;
}

.contents-exit-done {
  opacity: 0;
  transform-origin: center;
  transform: translateX(100%) scaleX(0);
}

@media (min-width: 768px) {
  .about>p {
    margin: 0 15vw;
    font-size: 2.5rem;
  }
  .skill-list {
    margin: 100px 15vw;
    grid-template-columns: repeat(5,80px);
    gap: 100px;
  }
}

