footer {
  background-color: rgb(19,25,28);
  height: 15vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: 30px;
}

footer>h3 {
  margin: 0;
  font-size: 1.8rem;
}

footer>div {
  display: flex;
  justify-content: center;
  gap: 20px;
}

@media (min-width: 768px) {
  footer {
    height: 20vh;
    gap: 50px;
  }
  footer>h3 {
    font-size: 3rem;
  }
  
  footer>div {
    gap: 100px;
  }
  footer>div>* {
    transform-origin: center;
    transform: scale(2);
  }
}