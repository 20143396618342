.project-card {
  border: 1px #fff solid;
  height: 100vw;
  margin: 0 10vw;
  background-color: rgb(19,25,28);
  position: relative;
}

.project-card>img {  
  height: 42%;
  object-fit: contain;
}
.project-card>p {
  margin: 0 10px ;
  height: 25%;
  font-size: 1.1rem;
}

.project-buttons {
  display: flex;
  flex-flow: column;
  margin: 10px;
  justify-content: flex-start;
  gap: 1rem;
  height: 5%;
  align-items: center;  
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (min-width: 768px) {
  .project-card {
    height: 50vh;
  }
  .project-card>img {
    max-width: 80%;
    height: 60%;
    object-fit: contain;  
  }
  .project-card>h3 {
    font-size: 3rem;
    margin-top: 10px;
  }
  .project-card>p {
    font-size: 1.5rem;
    margin-bottom: 5%;
    height: 10%;
  }
  .project-buttons {
    gap: 50px;
  }
  .project-buttons>* {
    transform: scale(1.5) translateX(-15%);
  }
}

