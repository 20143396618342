.project {
  height: 85vh;
  padding-top: 2rem;
  box-sizing: border-box;
}

.project>h2 {
  margin-top: 0;
}

.project-gallery {  
  display: flex;
  align-items: center;  
  justify-content: center;
}

.project>p {
  margin: 0 0 20px;
}

.carousel-root {
  overflow: hidden;
}

.control-arrow {
  width: 5rem;
}

@media (min-width: 768px) {
  .project {
    height: 80vh;
  }
  .project>p {
    font-size: 2rem;
  }
}

.lds-ring {
  position: relative;
  width: 80px;
  height: 80px;
  left: calc(50% - 40px);
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;  
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}