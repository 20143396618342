.App {
  text-align: center;
}

a {
  color: #fff;  
}

hr {
  color: #fff;
  width: 90%;
}

@media (min-width: 1200px) {
  h2 {
    font-size: 5rem;
  }
}
